import staffers from "./staffers";
import chats from "./chats";
import groups from "./groups";
import requestAResponseFromWork from "./requestAResponseFromWork";

export default {
  namespaced: true,
  modules: {
    chats,
    staffers,
    groups,
    "request-a-response-from-work": requestAResponseFromWork,
  },
};
