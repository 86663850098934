import headTeacher from "./head-teacher";
import teacher from "./teacher";
import mediaStaffer from "./media-staffer";
import staffer from "./staffer";

export default {
  namespaced: true,
  modules: {
    "head-teacher": headTeacher,
    teacher,
    "media-staffer": mediaStaffer,
    staffer,
  },
};
