const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/roles",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    list({ dispatch, state, rootState }, options = {}) {
      options["company"] = rootState.company._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    check({ dispatch, state, rootState }, value) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/check/${value}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
  },
};
