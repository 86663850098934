import { serialize } from "@/utils";

export default {
  namespaced: true,
  state: {
    path: "aljabr/telegram-api/head-teacher/groups",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
  },
};
