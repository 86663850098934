import { serialize } from "@/utils";

export default {
  namespaced: true,
  state: {
    path: "aljabr/telegram-api/staffer/request-from-work",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    new({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/new`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
  },
};
