const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/camps",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    addGroup({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-group`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    getBranchGroups({ dispatch, state, rootState }, branchId) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/branch-groups/${branchId}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addStudent({ dispatch, state, rootState }, data) {
      return dispatch("FETCH", { url: `${state.path}/add-student`, method: "POST", data }, { root: true });
    },
    updateGroup({ dispatch, state, rootState }, data) {
      return dispatch("FETCH", { url: `${state.path}/update-group`, method: "POST", data }, { root: true });
    },
    addPayment({ dispatch, state, rootState }, data) {
      return dispatch("FETCH", { url: `${state.path}/add-payment`, method: "POST", data }, { root: true });
    },
  },
};
