const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/contracts",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      options["academicYear"] = rootState.aljabr.selectedAcademicYear._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    list({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    check({ dispatch, state, rootState }, number) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/check`,
          method: "POST",
          data: { number },
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      data["branch"] = rootState.aljabr.selectedBranch._id;

      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    id({ dispatch, state, rootState }, value) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/id/${value}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    teachers({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/teachers?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    uploadScaneredContract({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/upload-scanered-contract`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    uploadScaneredRulesAgree({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/upload-scanered-rules-agree`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    uploadScaneredApplication({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/upload-scanered-application`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    sendContractAction({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-contract-action`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    save({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/save`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    remove({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/remove`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    rePut({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/re-put`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    sale({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/sale`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
  },
};
