const { serialize } = require("../../utils");

export default {
  namespaced: true,
  state: {
    path: "aljabr/telegram",
  },
  actions: {
    auth({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/auth`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    platformDeveloperTasks({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/platform-developer-tasks?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    saveTaskForPlatformDeveloper({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/save-platform-developer-task`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    addTaskForPlatformDeveloper({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-platform-developer-task`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    tasks({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/tasks?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    removeTask({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/remove-task`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    companies({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/companies?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    branches({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/branches?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    staffers({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/staffers?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    myTasks({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/my-tasks?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    myTask({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/my-task?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    setTaskStatus({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/set-task-status`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    taskRating({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/set-task-rating`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    payments({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/payments?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    paymentsByDates({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/payments-by-dates?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    confirmPayments({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/confirm-payments`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    spendings({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/spendings?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    reports({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/reports?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    groups({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/groups?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    teacherGroups({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/teacher-groups?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    todayTeacherLessons({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/today-teacher-lessons?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sendNewHomeWork({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-new-homework`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    sciences({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/sciences?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    visits({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/visits`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    knowledgeGroups({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/knowledge-groups?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addBonusFine({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-bonus-fine`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    prePaymentRequests({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/pre-payment-requests?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sendPrePaymentRequest({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-pre-payment-request`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    requestedEditOrRemoveContracts({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/requested-edit-or-remove-contracts?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    confirmRequestedEditOrRemoveContract({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/confirm-requested-edit-or-remove-contract`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    myRequestedSpendings({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/my-requested-spendings?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sendSpendingRequest({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-spending-request`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    removeRequestedSpending({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/remove-spending-request`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    spendingsRequests({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/requested-spendings?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sendSpendingRequestByDirector({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-spending-request-by-director`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    sendReportSpendingRequest({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-report-spending-request`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    chats({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/chats?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addNewChat({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-new-chat`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    addNewMessageToChat({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-new-message-to-chat`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    teacherHomeWorks({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/teacher-waiting-not-rated-homeworks?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sendHomeWorkRatings({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-homeworks-ratings`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    groupsForBehavior({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/groups-for-behavior?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addOnLesson({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/save-group-behaviors`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    onLessons({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/on-lessons?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    getGroupForMediaStaffer({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/groups-for-media-staffer?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addOnLesson({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-on-lesson`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    addOnLessonWithVideo({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-on-lesson-with-video`,
          method: "VID",
          data,
        },
        { root: true }
      );
    },
    chatsForDirector({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/chats-for-director?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sendStafferDebugData({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-staffer-debug-data`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    saveGroupBehaviors({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/save-group-behaviors`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    teacherLessonsSchedule({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-teachers-lessons-schedules?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addGroupBonusFine({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-group-bonus-fine`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    getRoles({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-roles?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addNewMessageToChatByDirector({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-new-message-to-chat-by-director`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    getTeacherLessonSciences({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-teacher-lesson-sciences?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    getTeacherGroupsByScience({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-teacher-groups-by-science?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    teacherOptionalLessons({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/teacher-optional-lessons?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sendNewOptionalLesson({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-new-optional-lesson`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    optionalLessonsForConfirm({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/optional-lessons-for-confirm?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sendConfirmOptionalLesson({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-confirm-optional-lesson`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    getBranches({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-branches?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addNewInterview({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-new-interview`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    getInterviews({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-interviews?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    requestAResponseFromWorks({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-request-a-response-from-work?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    getStafferPrePaymentRequests({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-staffer-pre-payment-requests?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    sendStafferPrePaymentRequest({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-staffer-pre-payment-request`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    sendNewRequestAResponseFromWork({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-new-request-a-response-from-work`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    "director-salaries"({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-salaries?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    "director-salary-fine-save"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-salary-fine-save`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    "director-salary-fine-remove"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-salary-fine-remove`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    "director-salary-bonus-save"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-salary-bonus-save`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    "director-salary-bonus-remove"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-salary-bonus-remove`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    "director-salary-bonus-add"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-salary-bonus-add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    "director-salary-fine-add"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-salary-fine-add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    "director-salary-confirm"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-salary-confirm`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    "my-dayly-report"({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/my-dayly-report?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    "send-my-dayly-report"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-my-dayly-report`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    spendingsRequestByID({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/spendings-request-by-id?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    getInterviewsForInterviewer({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-interviews-for-interviewer?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    getNewInterviews({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-new-interviews?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    "director-optional-lessons-for-reports"({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-optional-lessons-for-reports?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    "director-questionnaires-for-reports"({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-questionnaires-for-reports?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    "director-academic-years"({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-academic-years?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    "director-contracts-for-reports"({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/director-contracts-for-reports?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    spendingsByDates({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/spendings-by-dates?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
  },
};
