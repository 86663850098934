import { serialize } from "@/utils";

export default {
  namespaced: true,
  state: {
    path: "aljabr/telegram-api/teacher/chats",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    reports({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/reports?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    "add-message"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-message`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    "change-teacher"({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/change-teacher`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    new({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/new`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
  },
};
