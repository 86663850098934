export default {
  namespaced: true,
  state: {
    path: "abco/statistics",
  },
  actions: {
    studentsCount({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/students-count?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    groupsCount({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/groups-count?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    teachersCount({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/teachers-count?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    staffersCount({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/staffers-count?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
  },
};
