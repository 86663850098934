const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/reports",
  },
  actions: {
    contracts({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/contracts?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    payments({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/payments?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    spendings({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/spendings?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    optionalLessons({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/optional-lessons?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    homeworks({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/homeworks?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    behavior({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/behavior?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    chats({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/chats?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    attendance({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/attendance?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
  },
};
