import branches from "./branches";
import statistics from "./statistics";
import groups from "./groups";
import teachers from "./teachers";
import roles from "./roles";
import staffers from "./staffers";
import contracts from "./contracts";
import telegram from "./telegram";

export default {
  namespaced: true,
  modules: {
    branches,
    staffers,
    contracts,
    statistics,
    groups,
    teachers,
    roles,
    telegram,
  },
};
