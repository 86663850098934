export default {
  namespaced: true,
  state: {
    path: "abco/teachers",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${Object.entries(options)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join("&")}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    list({ dispatch, state, rootState }) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list/?branch=${rootState.aljabr.selectedBranch._id}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    last({ dispatch, state, rootState }) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/last/?branch=${rootState.aljabr.selectedBranch._id}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      data["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
  },
};
