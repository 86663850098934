const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/staffer-tasks",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      options["company"] = rootState.company._id;
      options["short"] = rootState.company.short.toLowerCase();
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    announcements({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      options["company"] = rootState.company._id;
      options["short"] = rootState.company.short.toLowerCase();
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    daily({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      options["company"] = rootState.company._id;
      options["short"] = rootState.company.short.toLowerCase();
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      data["branch"] = rootState.aljabr.selectedBranch._id;
      data["creator"] = rootState.profile._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    id({ dispatch, state, rootState }, value) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/id/${value}?short=${rootState.company.short.toLowerCase()}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    save({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/save`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    saveComment({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/save-comment`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    addComment({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-comment`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    remove({ dispatch, state, rootState }, id) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/remove/${id}`,
          method: "DELETE",
          data: {},
        },
        { root: true }
      );
    },
  },
};
