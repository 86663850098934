const { serialize } = require("../../utils");

export default {
  namespaced: true,
  state: {
    path: "aljabr/staffers",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      options["company"] = rootState.company._id;
      options["short"] = rootState.company.short.toLowerCase();
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    list({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    all({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/all?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    check({ dispatch, state, rootState }, value) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/check/${value}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      data["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    id({ dispatch, state, rootState }, value) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/id/${value}?short=${rootState.company.short.toLowerCase()}&branch=${rootState.aljabr.selectedBranch._id}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    teachers({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/teachers?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    update({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/update`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    bioUpdate({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/bio-update`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    demission({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/demission`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    visits({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/visits?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    stafferVisits({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/visits/${options.id}?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    visitsByMonthAndRole({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/visits-by-month-and-role/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    stafferBotId({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/staffer-bot-id`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    simCards({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/sim-cards?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    removeSimCard({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/remove-sim-card`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    saveWorkingDays({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/save-working-days`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    goneWithNextMonth({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/gone-with-next-month`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    deleteWorkingDay({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/remove-working-day`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    getWorkingDays({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/get-working-days?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
  },
};
