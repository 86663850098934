const { serialize } = require("../../utils");
export default {
  namespaced: true,
  state: {
    path: "aljabr/students",
  },
  actions: {
    get({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      options["academicYear"] = rootState.aljabr.selectedAcademicYear._id;
      options["company"] = rootState.company._id;
      options["short"] = rootState.company.short.toLowerCase();
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    list({ dispatch, state, rootState }, options) {
      options["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    check({ dispatch, state, rootState }, value) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/check/${value}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    add({ dispatch, state, rootState }, data) {
      data["branch"] = rootState.aljabr.selectedBranch._id;
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    id({ dispatch, state, rootState }, value) {
      if (rootState.authenticated) {
        return dispatch(
          "FETCH",
          {
            url: `${state.path}/id/${value}`,
            method: "GET",
            data: {},
          },
          { root: true }
        );
      }
    },
    update({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/update`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    updateParents({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/update-parents`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    sendMessageToParent({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/send-message-to-parent`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    studentVisits({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/visits/${options.id}?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    listForAddGroup({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/list-for-add-group?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    addStudentToGroup({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/add-student-to-group`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    changeBranchAndAcademicYear({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/change-branch-and-academic-year`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    parentBotId({ dispatch, state, rootState }, telegramID) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/parent-bot-id?telegramID=${telegramID}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
    updateByBirthCertificate({ dispatch, state, rootState }, data) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/update-by-birth-certificate`,
          method: "POST",
          data,
        },
        { root: true }
      );
    },
    getOptionalLessons({ dispatch, state, rootState }, options) {
      return dispatch(
        "FETCH",
        {
          url: `${state.path}/optional-lessons?${serialize(options)}`,
          method: "GET",
          data: {},
        },
        { root: true }
      );
    },
  },
};
